<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          HOW TO CONTACT US
        </h1>
        <p>
          Since we believe, that having easy access to a direct line with us is important, we are offering you two ways to contact us: personal and guest.
        </p>
        <p>
          A personal one, to which you gain access right after registering a new account for the <b>Fulfillin</b> system, can
          be accessed on the main screen, pressing a button a sticky and always-there-for-you button <b><i>Help</i></b> on the left
          corner bottom. After pressing this button, you’ll be welcomed with a support chat widget on the right side,
          allowing you to contact a key person to solve your problems and answer your questions!
        </p>
        <img
          src="../../../assets/images/help/howToContactUs/1.png"
          alt=""
        >
        <p>
          Guest one means that we will not be able to have much information about you and we might not be able to
          assist you with all your questions and needs. But don’t worry, we are always ready to have you
          covered – when contacting in this way, please be sure to provide us with all possible information and
          to describe your problem/question/etc. in one and first message. This will allow us to prepare the
          answer/solution faster and more efficiently. To access a guest contact form, all you have to do is go to
          the main <b>Fulfillin</b> website page, press the button on the right bottom corner and see a contact widget open
          for you. Now, it is time to ask us a question!
        </p>
        <img
          src="../../../assets/images/help/howToContactUs/2.png"
          alt=""
        >
        <p>
          That’s it! Everything is this simple – like two plus two.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HowToContactUs',
  components: {},
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 80%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
      @media (max-width: 1440px) {
        transition: .5s all ease;
        max-width: 100%;
      }
    }

    .container-fluid {
      background-color: white;
    }

    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    p {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }
  }
}
</style>
