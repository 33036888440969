<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          USING THE CONFIGURATOR
        </h1>
        <p>
          For more details on <b><i>using configurators</i></b> please refer to
          <a href="https://assets.linnworks.com/support/listing-management/configurators/">general Linnworks documentation</a> about them,
          because <b>Mirakl</b> configurators are made by exactly
          the same principle that <b>Linnworks</b> is using for <b>Shopify</b> or <b>Amazon configurators</b>.
        </p>
        <p>
          Our <b>Mirakl configurator</b> currently supports only <b><i>OFFER creation</i></b> for the products that already exist on <b>Mirakl marketplace</b>.
          <b><i>Product creation by configurator</i></b> is planned for future updates. Currently for creating <b><i>PRODUCTS</i></b> that do not exist yet on <b>Mirakl</b> you
          will still need to do it by conventional <b>Mirakl</b> means.
        </p>
        <p>
          Also usually product creation on <b>Mirakl marketplaces</b> is manually approved by marketplace operators.
          That is why product creation would not be as automated as doing it on <b>Shopify</b> or <b>Amazon</b> for example.
        </p>
        <p>
          You can find and create <b>Mirakl configurator</b> under your <b>Linnworks account</b>: <br>
          <pre>
            Linnworks > Settings > Configurators > Other</pre>
        </p>
        <p>
          Generally <b>Mirakl configurator</b> appear in the dropdown menu under <b>Shopify configurator</b>
          and they should show up in the dropdown menu after <b>Mirakl channels</b> are added to your <b>Linnworks account</b>.
        </p>
        <b-img src="@/assets/images/help/Mirakl/MiraklConfigurator/1.png" />
        <p>
          Steps required to use <b>Mirakl configurator</b> for offer creation - only for products that already exist in <b>Mirakl marketplace </b>
          <i>(for creating products that do not exist yet on <b>Mirakl</b> you will still need to do it by conventional <b>Mirakl</b> means not by configurator)</i>:
          <ul>
            1) You will have to create <b><i>configurator</i></b> and fill all the mandatory attributes that are marked in <b><i>red under Attributes</i></b>.<br>
            2) You can also select any number of optional attributes marked in yellow.<br>
          </ul>
          <b-img
            src="@/assets/images/help/Mirakl/MiraklConfigurator/2.png"
            height="300px"
          />
          <ul>
            3) After configurator is created you need to go to <b><i>My Inventory</i></b>, add the <b>Mirakl channel <i>column</i></b> to the view.
            And then right-click on <b><i>that channel to "Create listing on Mirakl MP"</i></b>.
          </ul>
          <b-img
            src="@/assets/images/help/Mirakl/MiraklConfigurator/3.png"
          />
        </p>
        <hr>
        <h1
          class="header"
          style="font-size: 28px"
        >
          Creating Mirakl Configurator
        </h1>
        <p>
          To create <b>a Mirakl configurator</b>:
          <ul>
            1. Go to <b><i>Settings > Others ></i></b> select <b><i>Mirakl</i></b> in the drop-down menu
            <br>
            2. Click <b><i>+Add New</i></b> button
            <br>
            3. In the configurator wizard screen add the following details:
            <br>
            <ul>
              <b>• Configurator Name</b>: enter a name for the configurator for your own convenience.
              <b><i>The configurator name</i></b> can help differentiate between certain groups of items as
              <b><i>the same configurator can be used</i></b> to create listings for similar items
              <br>
              <b>• Channel</b>: select the <b>Mirakl account</b> that <b><i>this configurator</i></b> will use when creating new listings
            </ul>
            4. Click <b><i>Finish</i></b>
          </ul>
          <b><i>The configurator</i></b> is now created and you can configure other settings.
        </p>
        <hr>
        <h1
          class="header"
          style="font-size: 28px"
        >
          Editing Mirakl Configurator
        </h1>
        <p>
          When <b><i>the configurator</i></b>  is created make sure that you have configured all the sections. Below you will find a detailed guide for each of them
        </p>
        <h1
          class="header"
          style="font-size: 20px; margin-bottom: 0; padding-bottom: 0"
        >
          Attributes
        </h1>
        <p>
          In the <b><i>Attributes column</i></b>, select <b><i>those attributes</i></b> that you wish to use for <b><i>this configurator</i></b>.
          The number on the button in the <b>Mirakl Configurators <i>screen</i></b> will stand for <b><i>the number of attributes</i></b> that you have selected.
        </p>
        <p>
          There are <b><i>four attributes</i></b> that are required by <b>Mirakl</b> and must be present on the listing <i>(marked with <b>a red "i" icon</b>)</i>,
          the rest are optional. The list of <b>Mirakl <i>attributes</i></b> you can check
          <a href="https://help.linnworks.com/support/solutions/articles/7000045265-listing-tool-available-shopify-configurator-attributes">here</a>.
        </p>
        <p>
          <b><i>The Attributes section</i></b>  consists of two parts:
          <ul>
            <b>• Available attributes</b>: preset attributes from the <b>Mirakl back-end</b> that you wish to use for
        <b><i>this configurator</i></b> by clicking the <b><i>+</i></b> button.
            <br>
            <b>• Selected attributes</b>: every listing created via <b><i>this configurator</i></b> will be able <b><i>to use attributes</i></b> from this table.
            When selected, you can specify where <b>Linnworks</b> should pick the information up from - the
            extended property of the item or from <b><i>the Default Value column</i></b> in this screen.
          </ul>
          Supplying the attribute values will update them within the details of every item on <b>Mirakl</b>.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'

export default {
  name: 'MiraklConfigurator',
  components: {
    BImg,
  },
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 100%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
    }

    .container-fluid {
      background-color: white;
    }

    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    a {
      color: #0C4879;
      text-decoration: #0C4879 underline;
    }

    p, ul, pre {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }

    ul {
      list-style: circle;
    }

    pre {
      padding: 5px;
      border: 1px solid gray;
      border-radius: 5px;
    }
  }
}
</style>
