<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          OBTAINING PRICING CREDENTIALS
        </h1>
        <p>
          <b>DB Schenker</b> uses a different way to calculate the price for the shipment. For the current version,
          it is only possible to calculate the price for <b><i>Europe</i></b>, following <b>DB Schenker Europe</b> pricing sets and rules
          <b><i>(Sweden is not included)</i></b>. The pricing is done by general rules and is calculated for the general
          shipment – no dangerous goods, no long goods, and no optional selections.
          <u>It is also important to mention that pricing may not be always calculated, as there are some locations where the price is not obtainable.</u>
        </p>
        <p>
          First, we must subscribe to <b><i>a pricing webservice</i></b>, which will allow us to calculate the price. The starting
          point is logging into <b><i>eSchenker</i></b> and navigating to <b><i>Usage Registration</i></b> and sending a request for a
          webservice approval.
        </p>
        <img
          src="../../../assets/images/help/DBSchenker/ObtainingPricing/1.png"
          alt=""
        >
        <p>
          After getting your <b><i>Pricing webservice</i></b> approved, you are to contact the <b><i>eSchenker support team</i></b>
          and request for credentials to pricing webservice. They are usually to respond fast, but if it is your first
          time getting this done – it might take a little bit while.
        </p>
        <p>
          After you acquired your credentials for pricing webservice, you are enabled to enter them into the <b><i>DB Schenker
          shipping integration configuration’s page</i></b> and move on with your configuration.
        </p>
        <img
          src="../../../assets/images/help/DBSchenker/ObtainingPricing/2.png"
          alt=""
        >
        <p>
          That’s it! Congratulations, you have obtained access to <b><i>the shipping pricing web service</i></b>!
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HowToProductImages',
  components: {},
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 100%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
    }

    .container-fluid {
      background-color: white;
    }

    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    p, ul {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }

    ul {
      list-style: circle;
    }
  }
}
</style>
