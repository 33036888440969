<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          HOW TO ADD BOX SIZE/WEIGHT?
        </h1>
        <p>
          Why is <b><i>adding box size and weight</i></b> important for your shipment?
        </p>
        <p>
          When you are finishing up your shipment on Amazon, it requests you to provide box content and
          box sizes, and weight. If your shipment contains more than one <b><i>SKU</i></b> per box (a total of 15 boxes or less),
          you will receive four (4) options from which to choose on how you wish to provide the content inside the boxes:
        </p>
        <p>
          <b>1. 2D Barcode</b><br>
          Provide box information via 2D barcodes<br>
          <b><i>(FEATURE COMING SOON & IN-DEVELOPMENT)</i></b>
        </p>
        <p>
          <b>2. Use web form</b><br>
          Enter box content information into a form on webpage
        </p>
        <p>
          <b>CONS</b>: This can be a lot of manual work and often due to multiple boxes, this option will not be available.
        </p>
        <p>
          <b>3.Upload file</b><br>
          Fill out a pack list and upload it to Seller CentralFeature that we will be explaining further on.
        </p>
        <p>
          <b>PROS:</b> No manual work & <b>Fulfillin</b> generates everything for you.
        </p>
        <p>
          <b>4. Skip box information and apply manual processing fee</b>. Amazon will manually process your box contents.
        </p>
        <p>
          <b>CONS:</b> You get charged for using this method (extra expenses).
        </p>
        <hr>
        <p>
          <b>ADDING BOX SIZE AND WEIGHT</b>
        </p>
        <p>
          Once you have finished the shipment and you wish to input <b>box sizes and weight</b>, all you need to do is:
        </p>
        <p>
          <ul>
            1. Select your <b><i>complete box</i></b> from the dropdown selection<br>
            2. Under your box number, add your box <b><i>Height, Length, Width</i></b> and <b><i>Weight</i></b>. It will automatically save the inserted values.<br>
          </ul>
        </p>
        <img
          src="../../../assets/images/help/howToSizeWeight/1.png"
          alt=""
        >
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HowToProductImages',
  components: {
  },
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 100%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
    }

    .container-fluid {
      background-color: white;
    }

    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    p {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }
  }
}
</style>
