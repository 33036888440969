<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          GENERAL INFORMATION
        </h1>
        <h1
          class="header"
          style="font-size: 28px"
        >
          Supported features
        </h1>
        <p>
          <ul style="font-weight: normal;">
            <li>
              Inventory Updates
            </li>
            <li>
              Inventory Mapping
            </li>
            <li>
              Channel Tax Settings
            </li>
            <li>
              Location Mapping
            </li>
            <li>
              Order Cancellations
            </li>
            <li>
              Order Download
            </li>
            <li>
              Order Despatch
            </li>
            <li>
              Order Refunds
            </li>
            <li>
              Payment Mapping
            </li>
            <li>
              Price Change
            </li>
            <li>
              Shipping Mapping
            </li>
            <li>
              Listing Creation (only for products that already exist on the marketplace)
            </li>
          </ul>
        </p>
        <hr>
        <h1
          class="header"
          style="font-size: 28px"
        >
          List of Mirakl marketplaces:
        </h1>
        <p>
          (our channel integration supports any <b>Mirakl marketplace</b> even if it is not on this list yet)
        </p>
        <div class="tableParent">
          <div class="tableChild">
            <ul>
              ABB - Brazil (SOLEX)<hr>
              ABB - India (SOLEX)<hr>
              Accor Benelux<hr>
              Accor DE<hr>
              Accor ES/PT<hr>
              Accor PL<hr>
              Accor UAE<hr>
              Accor UK<hr>
              AccorHotels<hr>
              Agora World<hr>
              Ahlens<hr>
              Airbus Helicopters (SOLEX)<hr>
              Aksal Group<hr>
              Al-Futtaim<hr>
              Alltricks<hr>
              Altitude Sports<hr>
              Aniel<hr>
              Animalis<hr>
              ASOS<hr>
              Ateliers d'Art de France<hr>
              Atlas For Men<hr>
              Auchan<hr>
              Axione<hr>
              B&Q (Kingfisher)<hr>
              BayWa AG<hr>
              Bed Bath & Beyond<hr>
              BHV<hr>
              Bigbang<hr>
              Blokker<hr>
              Boohoo - Debenhams<hr>
              Boulanger<hr>
              Brico Privé<hr>
              Bricomarché<hr>
              Bulevip<hr>
              BUT<hr>
              C10<hr>
              Campadre<hr>
              Carethy<hr>
              Carrefour Brazil<hr>
              Carrefour France<hr>
              Carrefour Spain<hr>
              Casa del Libro<hr>
              Catch<hr>
              Central Department Store<hr>
              Changi Airport<hr>
              Chronopost - Pour De Bon<hr>
              Click Central<hr>
              CMA CGM<hr>
              Coca Cola Hellenic Bottling Company (HBC)<hr>
              Conforama<hr>
              Conrad<hr>
              Coperama<hr>
              Coppel<hr>
              Coravin<hr>
              Creavea<hr>
              Darty<hr>
              DE - Degussa Bank - Mivo<hr>
              Decathlon Cube BE<hr>
              Decathlon Cube Belgium<hr>
              Decathlon Cube Germany<hr>
              Decathlon Cube Italy<hr>
              Decathlon Cube Netherlands<hr>
              Decathlon Cube Poland<hr>
              Decathlon Cube UK<hr>
              Decathlon Hungary<hr>
              Decathlon India<hr>
              Decathlon Padél<hr>
              Decathlon Portugal<hr>
              Decathlon Romania<hr>
              Decathlon Russia<hr>
              Decathlon Singapore<hr>
              Decathlon Taiwan<hr>
              Devianne<hr>
              Dia Styling Co.<hr>
              Distriartisan<hr>
              Dormify<hr>
              Dotz<hr>
              Douglas (SOLEX)<hr>
              El Corte Ingles<hr>
              Elkjop Finland<hr>
              Ellos<hr>
              Empik<hr>
              Eprice<hr>
              Estrella Galicia<hr>
              Exelon<hr>
              Express<hr>
              FeelUnique<hr>
              Fnac<hr>
              Fonq<hr>
              Food Service Exchange LLC<hr>
              France Boissons<hr>
              Freedom Furniture <hr>
              GACD<hr>
              Galeria Inno<hr>
              Galeries Lafayette<hr>
              Giant Eagle<hr>
              Go Sport<hr>
              Greenweez<hr>
              H&M - Afound<hr>
              H&M Home<hr>
              Happytal<hr>
              Harvey Nichols<hr>
              Hotter (Unbound)<hr>
              HP Enterprise<hr>
              IBS<hr>
              Intermarché<hr>
              J.Crew<hr>
              Jennyfer<hr>
              Joules<hr>
              Kabum<hr>
              KaDeWe<hr>
              Kiabi FR<hr>
              Kleertjes<hr>
              Kroger<hr>
              La Poste<hr>
              La Redoute<hr>
              League<hr>
              Leclerc<hr>
              Leen Bakker<hr>
              Legend Corporate<hr>
              Leroy Merlin Brazil<hr>
              Leroy Merlin FR<hr>
              Leroy Merlin Italy<hr>
              Leroy Merlin South Africa<hr>
              Liverpool<hr>
              Macway<hr>
              Macy's Inc. (Macy's and Bloomingdale's)<hr>
              Madewell<hr>
              Magazine zum Globus AG<hr>
              Maisons du Monde<hr>
              Mango<hr>
              Manor<hr>
              Materiel CHR Pro<hr>
              Mathis Brothers<hr>
              Maty<hr>
              Maxeda DIY<hr>
              Maykers<hr>
              MediaMarkt Germany<hr>
              MediaMarkt Netherlands<hr>
              MediaMarkt Spain<hr>
              Metro Cash and Carry<hr>
              Minted<hr>
              Moda Operandi<hr>
              Mountain Warehouse<hr>
              Musgrave<hr>
              Namshi.com<hr>
              Nature et Découvertes<hr>
              Nocibé<hr>
              NTUC Fairprice<hr>
              Obelink<hr>
              Office Mate<hr>
              Onceit<hr>
              Pandacola<hr>
              Parts Town (SOLEX)<hr>
              PcComponentes<hr>
              Perfume's Club<hr>
              Phone House<hr>
              Pixmania<hr>
              Plus-que-pro.fr<hr>
              Powerbuy<hr>
              Premier Inc<hr>
              Première Vision<hr>
              PRICESMART<hr>
              Private Sports Shop<hr>
              Public.gr<hr>
              Pyramyd Air<hr>
              Retif<hr>
              Rinascente<hr>
              Ripley Chile<hr>
              Ripley Peru<hr>
              Robinson Department Store<hr>
              Rubix<hr>
              Rue du Commerce<hr>
              Sarenza (Ex. Monoprix)<hr>
              Satair<hr>
              Saturn DE<hr>
              Scentre Group<hr>
              Secret Sales<hr>
              SHARAF DG LLC<hr>
              Shop Apotheke<hr>
              Showroomprivé marketplace<hr>
              Siemens<hr>
              Simon Property<hr>
              Sonepar<hr>
              Sprinter<hr>
              Stadium<hr>
              Super Pharm (SOLEX)<hr>
              Superdrug (AS Watson)<hr>
              Ta-Ta S.A.<hr>
              Teknosa<hr>
              Telekom Slovenije<hr>
              Thales<hr>
              Tiendanimal<hr>
              Tradeinn<hr>
              TradeSquare<hr>
              Tranchant - Entre chasseurs<hr>
              Trendy Foods<hr>
              Truffaut<hr>
              Turner Price<hr>
              Twil<hr>
              Ubaldi<hr>
              Unilae<hr>
              United Farmers of Alberta<hr>
              Urban Outfitters<hr>
              Vanden Borre<hr>
              Venca<hr>
              Vente Unique<hr>
              VidaXL<hr>
              Virtual Expo<hr>
              Winesitting<hr>
              Wook<hr>
              Worten<hr>
              WoW Shop<hr>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HowToProductImages',
  components: {},
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 100%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
    }

    .container-fluid {
      background-color: white;
    }

    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    .tableParent {
      .tableChild {
        height: 100%;
        background-color: whitesmoke;
        border-radius: 10px;
        padding-top: 15px;
        padding-bottom: 5px;
        margin-bottom: 15px;
        ul {
          font-size: 16px;
        }
      }
    }

    p, ul, pre {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }

    ul {
      list-style: circle;
      font-weight: bold;
    }

    pre {
      padding: 5px;
      border: 1px solid gray;
      border-radius: 5px;
    }
  }
}
</style>
