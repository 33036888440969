<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          HOW TO USE FILTERS?
        </h1>
        <p>
          Filtering products is often necessary to see what products have you missed, which products require your
          attention, and which products are labeled sticker less or non-sticker less.
          Filtering helps you finish your shipments and helps you solve errors.
        </p>
        <p>
          Accessing Filters:
        </p>
        <p>
          <ul>
            1. Select <b><i>the Shipments</i></b> section<br>
            2. Select your desired shipment<br>
            3. Select the filtering option that you wish to use – <b><i>Show, Label Type</i></b> or <b><i>Box</i></b><br>
          </ul>
        </p>
        <ul>
          <b>Show - Displays:</b>
          <ul>
            – Completed,<br>
            – Pending – <b><i>All</i></b> (products that require attention),<br>
            – Pending – <b><i>QTY Less</i></b> (products that require attention where the quantity is not met<br>
            – Pending – <b><i>QTY More</i></b> (products that require attention where the quantity has been scanned over the required
            amount)
          </ul>
          <b>Label Type – Displays:</b>
          <ul>
            – Stickerless – products that are labelled with Merchant Barcode<br>
            – Non-stickerless – products that are labelled with Amazon Barcode
          </ul>
          <b>Box – Displays either all boxes within the shipment or each box separately.</b>
        </ul>
        <img
          src="../../../assets/images/help/howToUseFilters/1.png"
          alt=""
        >
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HowToProductImages',
  components: {},
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 100%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
    }

    .container-fluid {
      background-color: white;
    }

    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    p, ul {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }

    ul {
      list-style: circle;
    }
  }
}
</style>
