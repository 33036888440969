<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          PRICING
        </h1>
        <p>
          Free starter plan with all the features. Limits: 1 <b>Mirakl marketplace</b>, up to 10 orders per month,
          up to 500 EUR in sales per month, and up to 500 listings.
        </p>
        <p>
          A basic plan with all the features. Limits: 2 <b>Mirakl marketplaces</b>, up to 100 orders per month,
          up to 3000 EUR in sales per month, and up to 3000 listings. 20 GBP / 23 EUR / 28 USD per month VAT exclusive.
        </p>
        <p>
          Promotional plan with all the features. <b><i>Unlimited</i> marketplaces</b>, orders per month, sales per month,
          and listings until further notice. <b><i>35 GBP / 40 EUR / 48 USD per month VAT exclusive</i></b>.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HowToProductImages',
  components: {},
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 100%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
    }

    .container-fluid {
      background-color: white;
    }

    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    p, ul {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }

    ul {
      list-style: circle;
    }
  }
}
</style>
