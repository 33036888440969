<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          HOW TO MARK PRODUCTS AS COMPLETED OR PENDING
        </h1>
        <p>
          When collecting products, there is always a chance that products will be less than required or more than
          required in the shipment. If you’ve scanned an item more than required or less than required, the shipment
          item screen will show that the product is not complete. However, if you wish to approve these changes to the
          shipment, there is an option to mark products as complete. If you’ve completed your shipment but you wish to
          set it as incomplete (pending) or set a product as incomplete, you can mark the product as pending.
        </p>
        <p>
          To mark a product as complete or pending:
        </p>
        <p>
          <ul>
            1. Select your <b><i>Shipments</i></b> <br>
            2. Select your desired shipment <br>
            3. Select the products that you wish to mark as pending/complete using the <b><i>checkbox</i></b> next to the quantity <br>
            4. Select the <b><i>dropdown selection/arrow</i></b> on the right side (by default) of the product that you have checked <br>
            5. Then select your desired choice, either <b><i>mark as complete</i></b> or <b><i>mark as pending</i></b> <br>
          </ul>
        </p>
        <img
          src="../../../assets/images/help/howToMarkProducts/1.png"
          alt=""
        >
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HowToProductImages',
  components: {
  },
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 100%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
    }

    .container-fluid {
      background-color: white;
    }

    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    p {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }
  }
}
</style>
