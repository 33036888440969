<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          AVAILABLE SERVICES & INCOTERMS
        </h1>
        <p>
          <b>DB Schenker</b> shipping implementation for <b>Linnworks</b> currently supports <b><i>only the land booking option</i></b>. For
          future reference, there will be also options for <b><i>the ocean</i></b> and <b><i>air support</i></b>, including <b><i>LCL</i></b> and <b><i>FCL</i></b> ocean
          options. As of now, for <b><i>land booking</i></b>, we have 4 different services available:
        </p>
        <ul>
          DB SCHENKERsystem – auco<br>
          DB SCHENKERsystem premium – aucc<br>
          DB SCHENKERfull load – 71<br>
          DB SCHENKERpart load – 72
        </ul>
        <p>
          These services are available with their own set of incoterms, like <b><i>EXW, DDU, DDP</i></b> and et cetera. These
          incoterms come directly from <b>DB Schenker</b> according to their <b><i>booking API and we are not available to add more</i></b>.
          If you see that there’s one missing, it might mean that it is not available for you and you might need to look
          into a <b><i>different service</i></b>.
        </p>
        <p>
          It is also important to keep in mind that there might be a service or two that are not <b><i>not available</i></b> for
          your <b><i>eSchenker</i></b> user, which means that even if you have selected the specific service and made
          a booking – that booking might be canceled, so
          <u>it is very important to make sure you have access for your selected service</u>.
        </p>
        <p>
          We are always ready to assist you with any questions you might have about your integration configuration!
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HowToProductImages',
  components: {},
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 100%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
    }

    .container-fluid {
      background-color: white;
    }

    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    p, ul {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }

    ul {
      list-style: circle;
    }
  }
}
</style>
