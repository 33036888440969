<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          HOW TO SETUP THE CHANNEL
        </h1>
        <h1
          class="header"
          style="font-size: 28px"
        >
          MIRAKL CONFIGURATION
        </h1>
        <p>
          This topic will cover the process of <b><i>installing, configuration, and full launch of your integration</i></b>.
        </p>
        <p>
          <b>Mirakl marketplace (general) channel</b> integration is one extensive integration that supports every marketplace
          under <b>Mirakl</b>, as long the said <b><i>marketplace</i></b> is in <b><i>production</i></b> stage. <b><i>Production</i></b> stage means that <b><i>the marketplace
          is launched and fully functioning</i></b>, allowing customers worldwide to access and order items.
        </p>
        <p>
          Before setting up your integration please make sure that <b>following requirements are met</b>:
        </p>
        <p>
          <b>
            1) Mirakl marketplace should be in the production stage.
          </b>
          <br>
          <b>Mirakl marketplaces</b> in <b><i>the development & preproduction stages</i></b> are not supported because these other stages are often missing required features.
        </p>
        <p>
          <b>
            2) Your Mirakl store should be in "Open" status.
          </b>
          <br>
          <b>"Pending"</b>, <b>"Suspended"</b> or other store statuses also can not work with API so <b><i>integration</i></b> will not be able to download orders or offers until your store on <b><i>the marketplace</i></b> is fully open.
        </p>
        <b-img src="@/assets/images/help/Mirakl/GeneralInformation/1.png" />
        <p>
          <b>
            3) You should be using the main (admin) Mirakl marketplace account when authorizing.
          </b>
          <br>
          Accounts with <b>"Limited"</b> permissions might not have the necessary access to API key generation, offer manipulation, etc.
          Having <b>"All permissions"</b> access gives us the necessary permissions to fully employ integration features.
        </p>
        <p>
          <b>
            4) Offers have to be fully active in order to be downloaded to Linnworks.
          </b>
          <br>
          In order to download the offers to <b>Linnworks</b>, they must be in <b><i>"Active"</i></b> status, including prices and stock levels set. All offers that are marked as
          <b><i>"Pending"</i></b> or in the <b><i>"Pending Offers"</i></b> list will not be downloaded or updated.
        </p>
        <h1
          class="header"
          style="font-size: 28px"
        >
          General
        </h1>
        <p>
          Channel integration configuration is split into three different parts:
        </p>
        <ul>
          - Installation;<br>
          - Authorization;<br>
          - Configuration after authorization.<br>
        </ul>
        <h1
          class="header"
          style="font-size: 28px"
        >
          Installation
        </h1>
        <p>
          We highly suggest you view <a @click="scrollToVideo">this video</a> for a very easy on-how-to with a step-by-step each covering part, as it
          covers all the necessities, both required and optional, allowing you to understand better how the integration
          works, what it can do, and how does it do.
        </p>
        <hr>
        <p>
          When you have gone through the initial installation, you are required to authorize your Mirakl account with
          us, so we can receive the data needed to make calls to your marketplace. The authorization process will
          automatically send us your chosen marketplace, your seller ID, channel list, and so forth, so you don’t have
          to worry about that.
          The first step – open your integration’s configuration page and look for a button – “Authorize Mirakl
          marketplace.”
        </p>
        <b-img
          src="@/assets/images/help/Mirakl/MiraklMarketplaces/step_one.png"
          height="380"
        />
        <p>
          After pressing this button, the integration should open a new tab for you in your browser, leading you to a
          website, giving you a piece of small information about your next steps. On this page, you should look for a
          button, “Sign in with Mirakl,” which will send you to the Mirakl website.
        </p>
        <b-img
          src="@/assets/images/help/Mirakl/MiraklMarketplaces/step_two.png"
          height="320"
        />
        <p>
          When you are redirected to the Mirakl website, you might see a selection of different views. One of them –
          with a list of marketplaces to choose to which you would like to authorize to, and another one – saying you do
          not have any marketplaces under this user.
          When presented with a “warning” message saying “Unable to find your username in a marketplace”, you must log
          in on a different account. That can be done by simply pressing the “Click here to log out and use a different
          account” button at the very top of the page (tag 1). If you believe that there might be a cache problem or
          other misunderstandings, you can always proceed with the “Check again” button and see if that fixes your issue
          (tag 2).
        </p>
        <b-img
          src="@/assets/images/help/Mirakl/MiraklMarketplaces/step_three.png"
          height="430"
        />
        <p>
          Ideally, when your account selection is correct, and you log in with an account with access to your desired
          marketplace, you should be presented with a list of one or more marketplaces, depending on your account
          settings and how many marketplaces you work with. The process from there is straightforward – select your
          marketplace (tag 1), choose your shop (tag 2), and press the “Confirm button” (tag 3).
        </p>
        <b-img
          src="@/assets/images/help/Mirakl/MiraklMarketplaces/step_four.png"
          height="480"
        />
        <p>
          After that – click the “Confirm” button at the bottom of the page again and wait for the system to handle your
          authorization. This shouldn’t take longer than 15 seconds, and after that, you should see a confirmation
          message from approval – “Authorization has been successful.” Once you are presented with such a message, it
          means you are good to go. Proceed with the “Close the tab!” button, go back to your Linnworks tab, and save
          your configuration on the bottom with a button Save.
        </p>
        <b-img
          src="@/assets/images/help/Mirakl/MiraklMarketplaces/step_five.png"
          height="300"
        />
        <p>
          Once you have gone through authorization, you can freely configure your integration. Every single line of
          configuration provides you with information on what it does. It’s that simple. Just place your mouse cursor on
          the top of the blue question sign, and you should be presented with a message explaining what this setting
          does.
        </p>
        <b-img
          src="@/assets/images/help/Mirakl/MiraklMarketplaces/step_six.png"
          height="300"
        />
        <br>
        <p>
          For certain marketplaces, you have the option to select a specific channel that you wish to manage.
          If you wish to manage all channels together (including prices, stocks, and offers), select "All channels together".
          If you wish to manage a specific channel, select the channel accordingly from the dropdown list.
        </p>
        <b-img
          src="@/assets/images/help/Mirakl/MiraklMarketplaces/step_seven.png"
          height="300"
        />
        <p>
          That’s it! Your configuration and authorization are done. We understand that things like that can be slightly
          hard to swallow, so if you ever require any sort of help or guidance, don’t hesitate to contact our support
          team and ask for questions. They’ll always guide you through. &#x1F607;
        </p>
        <h1
          id="video"
          class="header"
          style="font-size: 28px"
        >
          Video instruction
        </h1>
        <iframe
          width="100%"
          height="720"
          src="https://www.youtube.com/embed/sPTnS_boWxA"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          style="margin-bottom: 24px"
        />
      </div>
    </section>
  </div>
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'

export default {
  name: 'HowToProductImages',
  components: {
    BImg,
  },

  methods: {
    scrollToVideo() {
      const id = 'video'
      const element = document.getElementById(id)
      const yOffset = -100
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({
        top: y,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 100%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
    }

    .container-fluid {
      background-color: white;
    }

    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    a {
      color: #0C4879;
      text-decoration: #0C4879 underline;
    }

    p, ul, pre {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }

    ul {
      list-style: circle;
    }

    pre {
      padding: 5px;
      border: 1px solid gray;
      border-radius: 5px;
    }
  }
}
</style>
