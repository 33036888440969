<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          PRICE CHANGE
        </h1>
        <p>
          If you want <b>Linnworks</b> to send prices from <b><i>Product pricing by channel</i></b>
          to the <b>Mirakl <i>Price Change</i></b>
          needs to be selected - it enable the <b><i>Price change</i></b> functionality.
          <b><i>Update Discount Price Instead of Price</i></b> is optional setting - checking that alone will
          not do anything if <b><i>Price Change</i></b> is not selected as well.
        </p>
        <p>
          So this optional setting is just used if you want to keep the <b><i>Price</i></b> on <b>Mirakl static</b>
          and update the <b><i>Discount Price</i></b>  from <b>Linnworks</b> to <b>Mirakl</b> instead.
          Normally the <b><i>default</i> Linnworks</b> feature set allows only to
          change the one price - <b><i>"Price"</i></b> on any <b><i>marketplaces</i></b> - <b>Amazon</b>, <b>eBay</b> etc.
        </p>
        <p>
          That is why we included this as a way to change the <b><i>"Discount Price"</i></b>
          instead - because we know there are sellers that would be happy to show that saving as discount on website.
          But that requires for the seller that is using this optional feature to take care of <b>Mirakl marketplace</b>
          <b><i>"Price" field</i></b> initial values himself - while creating <b><i>offer</i></b> on <b>Mirakl marketplace</b> or
          later by editing it manually or uploading the file.
        </p>
        <hr>
        <p style="margin-bottom: 10px">
          There are 2 ways to update the <b>Mirakl <i>offer pricing</i></b> from <b>Linnworks</b>:
        </p>
        <ul>
          <b>1. Recommended</b> – we recommend using it initially until you have tested the <b><i>price change</i></b> functionality
          and are familiar with all the settings.
          <b><i>Price update</i></b> of <b>Linnworks <i>Product pricing by channel</i></b> by default updates the field called <b><i>"Price"</i></b> on <b>Mirakl</b>.
          For his you need to enable only <b><i>“Price change”</i></b> checkbox. <br>
          <b-img
            src="@/assets/images/help/Mirakl/PriceChanges/1.jpg"
            style="margin-top: 10px"
          />
          <br>
          <b>2. Optional</b> – this is second option if you wish to update the <b><i>”Discount Price” field</i></b> on <b>Mirakl</b> and
          keep the value of <b><i>“Price” field</i></b> with no changes.
          You need to enable <b><i>BOTH “Price change” AND “Update Discount Price Instead of Price” checkboxes</i></b>.
          This way price update of <b>Linnworks</b> <b><i>Product pricing</i></b> by channel by will update the field called
          <b><i>"Discount Price"</i></b> on <b>Mirakl</b>. <br>
          <b-img
            src="@/assets/images/help/Mirakl/PriceChanges/2.jpg"
            style="margin-top: 10px"
          />
          <p style="margin-top: 10px">
            <b>
              Please remember:
            </b>
          </p>
          <ul style="margin-bottom: 15px">
            <li>
              That this means you will need to set the value of <b><i>Price field</i></b> on <b>Mirakl</b> manually
              (or by file upload to <b>Mirakl</b>) – <b>Linnworks</b> will not be able to update it.
            </li>
            <li>
              <b><i>Discount price</i></b> has to always be lower than sell price.
              If you will set <b>Linnworks <i>Product pricing</i></b> by channel higher than existing <b><i>Price</i></b> for that offer
              on <b>Mirakl</b> then there will be an error and no price update will
              be sent from <b>Linnworks</b> to <b>Mirakl marketplace</b>.
            </li>
          </ul>
          <p>
            As example if you want to keep <b><i>100 EUR</i></b> as a reference <b><i>Price</i></b> on <b>Mirakl</b> and set <b><i>the Discount Price of 80 EUR</i></b>
            from <b>Linnworks</b> then you can use this feature. Then any later <b><i>Discount Price changes</i></b> can
            be sent from <b>Linnworks</b> <b><i>Product pricing by channel</i></b> as long as this value is lower than the <b><i>Price</i></b>
            set manually on <b>Mirakl marketplace</b>.
          </p>
          <b-img src="@/assets/images/help/Mirakl/PriceChanges/6.jpg" />
          <p>
            In this example if you will try to send <b><i>Discount Price as 101 EUR</i></b>
            then it will cause <b><i>an error</i></b> and will not be set because it can not be higher that you have
            manually set as a (reference) <b><i>Price - 100 EUR</i></b>.
          </p>
          <b-img src="@/assets/images/help/Mirakl/PriceChanges/7.jpg" />
          <hr>
          <p>
            <b>
              Please note that:
            </b>
          </p>
          <ul style="margin-bottom: 15px">
            1. If you have selected <b><i>All channels together</i></b> in <b>Linnworks Mirakl channel</b> details then
            the <b><i>price change</i></b> will be sent to all the channels - <b><i>Offer Price</i></b> and also all
            the channels in the <b><i>Channel Pricing section</i></b>.
            In this example <b><i>Offer Price, Worten Online ES and Worten Online PT</i></b> will all get updated to <b><i>35 EUR</i></b>.
            <br>
          </ul>
          <b-img src="@/assets/images/help/Mirakl/PriceChanges/4.jpg" />
          <br>
          <b-img src="@/assets/images/help/Mirakl/PriceChanges/3.jpg" />
          <ul style="margin-bottom: 15px">
            2. <b><i>When single channel</i></b> is selected in <b>Linnworks Mirakl channel</b> details then <b><i>no price change</i></b>
            will be sent to <b><i>general price</i></b> - only specific channel will be updated separately.
          </ul>
          <p>
            For example if you have selected <b><i>Worten Online ES</i></b> in <b>Linnworks Mirakl channel</b> details
            then only the <b><i>Channel pricing on Worten Online ES channel</i></b> will be updated to <b><i>35 EUR</i></b>.
            <b><i>General Offer Price</i></b> will not be updated because it is not visible to that particular channel customers.
          </p>
          <b-img src="@/assets/images/help/Mirakl/PriceChanges/5.jpg" />
          <br>
          <b-img src="@/assets/images/help/Mirakl/PriceChanges/8.jpg" />
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'

export default {
  name: 'PriceChange',
  components: {
    BImg,
  },

  methods: {
    scrollToVideo() {
      const id = 'video'
      const element = document.getElementById(id)
      const yOffset = -100
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({
        top: y,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 40%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
      @media (max-width: 1170px) {
        max-width: 100%;
      }
    }

    .container-fluid {
      background-color: white;
    }

    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    a {
      color: #0C4879;
      text-decoration: #0C4879 underline;
    }

    p, ul, pre {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }

    ul {
      list-style: circle;
    }

    pre {
      padding: 5px;
      border: 1px solid gray;
      border-radius: 5px;
    }
  }
}
</style>
