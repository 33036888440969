<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          OOOPS!
        </h1>
        <h1
          class="header"
          style="font-size: 28px"
        >
          Sorry, we couldn't find this page! :(
        </h1>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'WrongUrl',
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 100%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
    }

    .container-fluid {
      background-color: white;
      padding-right: 300px;
    @media (max-width: 1440px) {
      padding-right: 10px;
      transition: .5s all ease;
    }
    }

    .header {
      padding-top: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    a {
      color: #0C4879;
      text-decoration: #0C4879 underline;
    }

    p, ul, pre {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }

    ul {
      list-style: circle;
    }

    pre {
      padding: 5px;
      border: 1px solid gray;
      border-radius: 5px;
    }
  }
}
</style>
