<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          ATTACHING DOCUMENTS TO THE SHIPMENT
        </h1>
        <p>
          If your shipping destination is the UK and you have to attach the invoice, one of the possible ways is to do so through
          <a href="https://eschenker.dbschenker.com/app/launch-page/public?language_region=en-US_US">
            <i>the eSchenker</i>
          </a>
          website.
        </p>
        <p>
          Login to your eSchenker and <b>find the tab <i>Booking</i>.</b>
        </p>
        <img
          src="../../../assets/images/help/DBSchenker/AttachingDocuments/1.png"
          alt=""
        >
        <p>
          After that, navigate to <b><i>My Bookings</i></b> on the left side panel and open up a search screen. In the
          search screen, find your shipment, select it and click <b><i>Add Documents.</i></b>
        </p>
        <img
          src="../../../assets/images/help/DBSchenker/AttachingDocuments/2.png"
          alt=""
        >
        <p>
          After that, simply select the document you want to attach to the shipment and it is done!
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HowToProductImages',
  components: {},
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 100%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
    }

    .container-fluid {
      background-color: white;
    }

    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    p, ul {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }

    ul {
      list-style: circle;
    }
  }
}
</style>
