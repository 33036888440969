<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          CUSTOMER ADDRESS ID
        </h1>
        <p>
          A customer address ID is required to identify a customer’s specific address when there is more
          than one defined in <b><i>the eSchenker</i></b> system. The easiest way to acquire
          the <b><i>ID</i></b> is to contact <b><i>eSchenker</i></b> support and ask
          for one to be assigned or do it by yourself under the <b><i>eSchenker system</i></b>.
        </p>
        <p>
          This <b><i>ID</i></b> is required for example when the shipper’s address is different from the pickup’s address,
          and also to identify the exact location when there is more than one company at the same building/street.
        </p>
        <p style="text-decoration: underline">
          <b><i>Customer address ID</i></b> is only used to identify the pickup’s
          address – the shipper’s address has no influence, it only appears on the invoice or specific times on the label if set so.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HowToProductImages',
  components: {},
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 100%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
    }

    .container-fluid {
      background-color: white;
    }

    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    p, ul {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }

    ul {
      list-style: circle;
    }
  }
}
</style>
