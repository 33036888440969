<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          QUESTIONS AND ANSWERS
        </h1>
        <p>
          <b>Question</b>: after integration, my channel inventory was not updated.
        </p>
        <p style="border-bottom: 2px solid black; padding-bottom: 10px; margin-bottom: 20px">
          <b>Answer</b>: one of the reasons might be because you did not initiate inventory download and linking.
          If this step was done, please keep in mind that it might take a little bit of time for <b>Linnworks</b> to
          send all the new quantities and prices, which is not instant.
        </p>
        <p>
          <b>Question</b>: what is the <b><i>Shop ID input field</i></b>?
        </p>
        <p style="border-bottom: 2px solid black; padding-bottom: 10px; margin-bottom: 20px">
          <b>Answer</b>: it shows the <b><i>Shop ID</i></b> of the current <b>Mirakl</b> store connected to this your <b>Linnworks channel</b>. It is for reference only.
        </p>
        <p>
          <b>Question</b>: is mapping of <b><i>payment methods</i></b> supported?</p>
        <p style="border-bottom: 2px solid black; padding-bottom: 10px; margin-bottom: 20px">
          <b>Answer</b>: Yes, <b><i>payment methods</i></b> mapping is fully supported.
          They are updated on daily basis from <b><i>payment methods</i></b>
          used in already processed orders. If you installed the integration and <b><i>no payment methods</i></b>
          are yet available in the <b>Linnworks channel</b> configuration <i>(Payment Methods dropdown)</i>
          then please process some orders and open the <b><i>Payment Methods configuration</i></b> the next day.
        </p>
        <p>
          <b>Question</b>: I have dispatched 50 orders, but only half of them went through. What’s wrong?
        </p>
        <p style="border-bottom: 2px solid black; padding-bottom: 10px; margin-bottom: 20px">
          <b>Answer</b>: nothing. The dispatched orders are being batched – 25 orders to be marked every 3-5 minutes.
          That means that having to dispatch all 50 orders might take from 6 to 10 minutes. This is because
          the <b>Mirakl marketplaces</b> have a limit on how many can be dispatched at once,
          forcing us to delay the job.
        </p>
        <p>
          <b>Question</b>: does the integration support product or offer listing from <b>Linnworks</b> to <b>Mirakl marketplaces</b>?
        </p>
        <p style="border-bottom: 0px solid black; padding-bottom: 10px; margin-bottom: 20px">
          <b>Answer</b>: integration supports <b><i>OFFER</i></b> creation by configurator for the products that already exist
          on <b>Mirakl marketplace</b>. Please see <b><i>the Configurator help section</i></b> for more details on it.<br>
          But integration currently does not support the <b><i>PRODUCT</i></b> listing to <b>Mirakl channels</b>,
          it is on the development plan but there is no estimated release date available yet.<br>
          Here is some general information about offer and product upload on any <b>Mirakl marketplace</b>:
        </p>
        <p>
          To upload <b><i>Offers/Products</i></b> into <b>Mirakl Marketplace</b>:
        </p>
        <ul>
          1. Navigate to section <b><i>My Inventory > Import from File</i></b><br>
          2. Download the template under the section – <b><i>Create or update my offers (stock, price, and so on)</i></b><br>
          3. Select <b><i>the File Template</i></b> you wish to download:<br>
          <ul>
            – <b><i>Offers</i></b> – Products that are already existing in <b>Mirakl Marketplace</b><br>
            – <b><i>Products</i></b> – For creating new products for the <b>Mirakl Marketplace</b><br>
          </ul>
          4. Fill out the format with the values requested in the files<br>
          5. Upload the file under the section <b><i>My Inventory > Import from File</i></b><br>
          5. 1. Select the file content of the file:<br>
          <ul>
            – <b><i>Offers</i></b> – Products that are already existing in <b>Mirakl Marketplace</b><br>
            – <b><i>Products</i></b> – For creating new products for the <b>Mirakl Marketplace</b><br>
          </ul>
          And press Import.
        </ul>
        <p>
          To Track your <b><i>Offer/Product Imports</i></b>, go under <b><i>My Inventory > Import from File</i></b>
        </p>
        <p>
          and on the left side select <b><i>“Track Offer Imports”</i></b> or <b><i>“Track Product Imports”</i></b>.
        </p>
        <p>
          For more information you can check on <b><i>the marketplace</i></b> or <b><i>contact your marketplace account manager</i></b>.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HowToProductImages',
  components: {},
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 100%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
    }

    .container-fluid {
      background-color: white;
    }

    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    p, ul, pre {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }

    ul {
      list-style: circle;
    }

    pre {
      padding: 5px;
      border: 1px solid gray;
      border-radius: 5px;
    }
  }
}
</style>
