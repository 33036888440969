<template>
  <transition name="mobile-nav">
    <div
      v-show="!mobileNav"
      class="faq"
    >
      <div class="menu">
        <div class="listSecond">
          <div
            class="list"
            :style="heightChange"
          >
            <h4
              class="header"
            >
              General
            </h4>
            <ul>
              <li>
                <router-link
                  class="link"
                  :to="{ name: 'documentationGeneral', params: { pageProp: 'contact-us' } }"
                >
                  How to contact us
                </router-link>
              </li>
              <li>
                <router-link
                  class="link"
                  :to="{ name: 'documentationGeneral', params: { pageProp: 'product-images' } }"
                >
                  How to import product images by EAN?
                </router-link>
              </li>
            </ul>

            <h4 class="header">
              Shipments
            </h4>
            <ul>
              <li>
                <router-link
                  class="link"
                  :to="{ name: 'documentationShipments', params: { pageProp: 'mark-products' } }"
                >
                  How to mark products as completed or pending?
                </router-link>
              </li>
              <li>
                <router-link
                  class="link"
                  :to="{ name: 'documentationShipments', params: { pageProp: 'product-labels' } }"
                >
                  How to print product labels – Printer Configuration Guide
                </router-link>
              </li>
              <li>
                <router-link
                  class="link"
                  :to="{ name: 'documentationShipments', params: { pageProp: 'box-size' } }"
                >
                  How to add box size/weight?
                </router-link>
              </li>
              <li>
                <router-link
                  class="link"
                  :to="{ name: 'documentationShipments', params: { pageProp: 'filters' } }"
                >
                  How to use filters?
                </router-link>
              </li>
              <li>
                <router-link
                  class="link"
                  :to="{ name: 'documentationShipments', params: { pageProp: 'new-box' } }"
                >
                  How to add a new box?
                </router-link>
              </li>
            </ul>

            <h4 class="header">
              Linnworks integrations
            </h4>

            <ul class="linnworks">
              <li
                v-b-toggle.collapse-1
                class="dropdown"
              >
                DB Schenker
              </li>
              <b-collapse id="collapse-1">
                <ul>
                  <li>
                    <router-link
                      class="link"
                      :to="{ name: 'documentationDbschenker', params: { pageProp: 'attaching-documents' } }"
                    >
                      Attaching documents to the shipment
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="link"
                      :to="{ name: 'documentationDbschenker', params: { pageProp: 'booking-webservice' } }"
                    >
                      Obtaining access key & subscribing to booking webservice
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="link"
                      :to="{ name: 'documentationDbschenker', params: { pageProp: 'pricing-credentials' } }"
                    >
                      Obtaining pricing credentials
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="link"
                      :to="{ name: 'documentationDbschenker', params: { pageProp: 'services-and-incoterms' } }"
                    >
                      Available services & Incoterms
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="link"
                      :to="{ name: 'documentationDbschenker', params: { pageProp: 'address-id' } }"
                    >
                      Customer Address ID
                    </router-link>
                  </li>
                </ul>
              </b-collapse>

              <li
                v-b-toggle.collapse-2
                class="dropdown"
              >
                Mirakl
              </li>
              <b-collapse id="collapse-2">
                <ul>
                  <li>
                    <router-link
                      class="link"
                      :to="{ name: 'documentationMirakl', params: { pageProp: 'general' } }"
                    >
                      General information
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="link"
                      :to="{ name: 'documentationMirakl', params: { pageProp: 'setup' } }"
                    >
                      How to setup the channel
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="link"
                      :to="{ name: 'documentationMirakl', params: { pageProp: 'qa' } }"
                    >
                      Questions and Answers
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="link"
                      :to="{ name: 'documentationMirakl', params: { pageProp: 'pricing' } }"
                    >
                      Pricing
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="link"
                      :to="{ name: 'documentationMirakl', params: { pageProp: 'price' } }"
                    >
                      Price Change
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="link"
                      :to="{ name: 'documentationMirakl', params: { pageProp: 'configurator' } }"
                    >
                      Using the configurator
                    </router-link>
                  </li>
                </ul>
              </b-collapse>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { BCollapse, VBToggle } from 'bootstrap-vue'

export default {
  name: 'HowToContactUs',
  components: {
    BCollapse,
  },

  directives: {
    'b-toggle': VBToggle,
  },

  props: {
    mobileNav: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
      default: 1080,
    },
  },

  data() {
    return {
      knowledgeBaseSearchQuery: '',
      kb: [],
      transitionName: 'mobile-nav',
    }
  },

  computed: {
    filteredKB() {
      const knowledgeBaseSearchQueryLower = this.knowledgeBaseSearchQuery.toLowerCase()
      return this.kb.filter(item => item.title.toLowerCase()
        .includes(knowledgeBaseSearchQueryLower) || item.desc.toLowerCase()
        .includes(knowledgeBaseSearchQueryLower))
    },
    heightChange() {
      return {
        height: `${this.height - 100}px`,
      }
    },
  },

  mounted() {
    window.addEventListener('resize', this.checkScreen)
    this.checkScreen()
  },

  created() {
    this.$http.get('/kb/data/knowledge_base')
      .then(res => {
        this.kb = res.data
      })
  },

  methods: {
    turnOffTransition() {
      if (this.mobile !== true) {
        this.transitionName = 'no-transition'
      }
    },
    checkScreen() {
      this.height = window.innerHeight
    },
  },
}
</script>

<style lang="scss" scoped>
.faq {
  width: 23%;
  margin-left: 20px;
  position: fixed;
  background-color: #1b1c1e;
  z-index: 96;
  height: 100vh;
  padding-top: 31px;
  padding-left: 40px;
  @media (max-width: 1170px) {
    width: 50%;
  }
  @media (max-width: 700px) {
    width: 80%;
  }
  .menu {
    background-color: #1b1c1e;
    position: relative;
    .listSecond {
      .list {
        //height: 900px;
        overflow-y: scroll;
        position: sticky;
        top: 60px;
        //@media screen and (max-height: 800px) {
        //  height: 700px;
        //}
      }
    }
    ::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    .header {
      font-family: 'Open Sans', sans-serif;
      font-size: 21px;
      display: inline-block;
      color: darkorange;
    }

    ul {
      list-style-image: url("../../assets/images/icons/list.png");
      margin-bottom: 10px;
      font-family: 'Barlow', sans-serif;
      font-size: 16px;

      li {
        margin-top: 10px;
        margin-bottom: 10px;
        color: white;
        margin-left: 20px;
        &:hover {
          padding-left: 10px;
          transition: all 0.3s ease-in-out;
        }
      }

      .link {
        color: white;

        &:hover {
          cursor: pointer;
        }
      }

      .dropdown {
        ul {
          border: 0;
        }
      }
    }

    .linnworks {
      list-style-image: url("../../assets/images/icons/list-dropdown.png");
      cursor: pointer;

      li {
        &:hover {
          padding-left: 10px;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}

.mobile-nav-enter,
.mobile-nav-leave-to {
  transform: translateX(-124%);
}

.mobile-nav-enter-active,
.mobile-nav-leave-active {
  transition: all 1s;
}

.mobile-nav-enter-to {
  transform: translateX(0);
  transition: all 1s;
}
</style>
