<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          HOW TO PRINT PRODUCT LABELS – PRINTER CONFIGURATION GUIDE
        </h1>
        <p>
          To <b>print single item labels</b>, simply follow these instructions:
        </p>
        <p>
          <ul>
            1. Select <b><i>Shipments</i></b> <br>
            2. Select your desired shipment <br>
            3. Select <b><i>Print PDF</i></b> <br>
          </ul>
        </p>
        <img
          src="../../../assets/images/help/howToProductLabels/1.png"
          alt=""
        >
        <p>
          To <b>print A4 labels</b>:
        </p>
        <p>
          <ul>
            1. Select <b><i>Shipments</i></b> <br>
            2. Select your desired shipment <br>
            3. Select <b><i>Dropdown Selection/Arrow</i></b> on the right side (by default) <br>
            4. Select <b><i>Print A4 labels</i></b>
          </ul>
        </p>
        <img
          src="../../../assets/images/help/howToProductLabels/2.png"
          alt=""
        >
        <p>
          <b>Configuration guide</b>
        </p>
        <p>
          <b>Single Label</b>
        </p>
        <p>
          <ul>
            1. Choose your small label printer.<br>
            2. Select <b><i>Your Setup</i></b> for paper size.<br>
            3. Select scale always <b><i>Fit to page</i></b>.<br>
            4. Print.
          </ul>
        </p>
        <img
          src="../../../assets/images/help/howToProductLabels/3.png"
          alt=""
        >
        <p>
          <b>Small label printer configuration guide</b>
        </p>
        <p>
          <ul>
            1. Select <b><i>Page setup</i></b><br>
            2. Select <b><i>Landscape</i></b> at Orientation (Label size 59.9 x 39.9)<br>
          </ul>
        </p>
        <img
          src="../../../assets/images/help/howToProductLabels/4.png"
          alt=""
        >
        <p>
          <ul>
            3. Select <b><i>Graphics</i></b><br>
            4. Select <b><i>None</i></b> at Dithering
          </ul>
        </p>
        <img
          src="../../../assets/images/help/howToProductLabels/5.png"
          alt=""
        >
        <p>
          <b>A4 Labels Page</b>
        </p>
        <p style="padding-left: 15px">
          1. Choose your A4 label printer.<br>
          2. Select A4 paper size.<br>
          3. Always select the <b><i>Default</i></b> selection for Paper Scale.<br>
        </p>
        <p>
          <b>Label page cutouts should always be 63.5 mm x 33.9 mm</b>
        </p>
        <img
          src="../../../assets/images/help/howToProductLabels/6.jpg"
          alt=""
        >
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HowToProductImages',
  components: {
  },
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 100%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
    }

    .container-fluid {
      background-color: white;
    }

    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    p {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }
  }
}
</style>
