<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          HOW TO ADD A NEW BOX?
        </h1>
        <p>
          What does <b>Add box</b> mean?
        </p>
        <p>
          <b>Add box</b> gives you the ability to add more boxes to your shipment, allowing you to manage your products in several boxes.
        </p>
        <p>
          <b>Accessing the screen</b>
        </p>
        <p>
          The <b>Add box</b> can be found as follows
        </p>
        <p>
          <ul>
            1. Select <b><i>Shipments</i></b><br>
            2. Select your desired shipment<br>
            3. Select <b><i>Add box</i></b>
          </ul>
        </p>
        <p>
          Now you have a new box in which you could add more products.
        </p>
        <img
          src="../../../assets/images/help/howToAddBox/1.png"
          alt=""
        >
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HowToProductImages',
  components: {
  },
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 100%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
    }

    .container-fluid {
      background-color: white;
    }

    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    p, ul {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }

    ul {
      list-style: circle;
    }
  }
}
</style>
