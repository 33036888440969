<template>
  <div class="documentation">
    <b-row class="faq">
      <b-col
        class="main"
        md="12"
        style="padding: 0;"
      >
        <div
          class="sidebar"
        >
          <div class="icon">
            <i
              class="fas fa-angle-left"
              :class="{'icon-active' : mobileNav}"
              @click="toggleMobileNav"
            />
          </div>
        </div>
        <div class="menu">
          <Sidebar
            :mobile-nav="mobileNav"
            @passPage="showPage"
          />
        </div>
        <div class="container">
          <div class="sections">
            <transition name="fade">
              <HowToContactUs v-show="page==='contact-us'" />
            </transition>
            <transition name="fade">
              <HowToProductImages v-show="page==='product-images'" />
            </transition>
            <transition name="fade">
              <HowToAddBox v-show="page==='new-box'" />
            </transition>
            <transition name="fade">
              <HowToMarkProducts v-show="page==='mark-products'" />
            </transition>
            <transition name="fade">
              <HowToProductLabels v-show="page==='product-labels'" />
            </transition>
            <transition name="fade">
              <HowToSizeWeight v-show="page==='box-size'" />
            </transition>
            <transition name="fade">
              <HowToUseFilters v-show="page==='filters'" />
            </transition>
            <transition name="fade">
              <AttachingDocuments v-show="page==='attaching-documents'" />
            </transition>
            <transition name="fade">
              <CustomerAdressID v-show="page==='address-id'" />
            </transition>
            <transition name="fade">
              <ObtainingAccessKey v-show="page==='booking-webservice'" />
            </transition>
            <transition name="fade">
              <ObtainingPricing v-show="page==='pricing-credentials'" />
            </transition>
            <transition name="fade">
              <ServicesAndIncoterms v-show="page==='services-and-incoterms'" />
            </transition>
            <transition name="fade">
              <Pricing v-show="page==='pricing'" />
            </transition>
            <transition name="fade">
              <PriceChange v-show="page==='price'" />
            </transition>
            <transition name="fade">
              <GeneralInformation v-show="page==='general'" />
            </transition>
            <transition name="fade">
              <SetupTheChannel v-show="page==='setup'" />
            </transition>
            <transition name="fade">
              <QuestionsAndAnswers v-show="page==='qa'" />
            </transition>
            <transition name="fade">
              <UsingTheConfigurator v-show="page==='configurator'" />
            </transition>
            <transition name="fade">
              <WrongUrl v-show="page==='wrong-url'" />
            </transition>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import Sidebar from '@/components/Help/Sidebar.vue'
import HowToContactUs from '@/components/Help/General/HowToContactUs.vue'
import HowToProductImages from '@/components/Help/General/HowToProductImages.vue'
import HowToAddBox from '@/components/Help/Shipments/HowToAddBox.vue'
import HowToMarkProducts from '@/components/Help/Shipments/HowToMarkProducts.vue'
import HowToProductLabels from '@/components/Help/Shipments/HowToProductLabels.vue'
import HowToSizeWeight from '@/components/Help/Shipments/HowToSizeWeight.vue'
import HowToUseFilters from '@/components/Help/Shipments/HowToUseFilters.vue'
import AttachingDocuments from '@/components/Help/DBSchenker/AttachingDocuments.vue'
import CustomerAdressID from '@/components/Help/DBSchenker/CustomerAdressID.vue'
import ObtainingAccessKey from '@/components/Help/DBSchenker/ObtainingAccessKey.vue'
import ObtainingPricing from '@/components/Help/DBSchenker/ObtainingPricing.vue'
import ServicesAndIncoterms from '@/components/Help/DBSchenker/ServicesAndIncoterms.vue'
import Pricing from '@/components/Help/Mirakl/Pricing.vue'
import GeneralInformation from '@/components/Help/Mirakl/GeneralInformation.vue'
import QuestionsAndAnswers from '@/components/Help/Mirakl/QuestionsAndAnswers.vue'
import SetupTheChannel from '@/components/Help/Mirakl/SetupTheChannel.vue'
import UsingTheConfigurator from '@/components/Help/Mirakl/UsingTheConfigurator.vue'
import PriceChange from '@/components/Help/Mirakl/PriceChange.vue'
import WrongUrl from '@/components/Help/WrongUrl.vue'

export default {
  name: 'Documentation',
  components: {
    Sidebar,
    HowToContactUs,
    HowToProductImages,
    HowToAddBox,
    HowToMarkProducts,
    HowToProductLabels,
    HowToSizeWeight,
    HowToUseFilters,
    AttachingDocuments,
    CustomerAdressID,
    ObtainingAccessKey,
    ObtainingPricing,
    ServicesAndIncoterms,
    Pricing,
    GeneralInformation,
    QuestionsAndAnswers,
    SetupTheChannel,
    UsingTheConfigurator,
    PriceChange,
    WrongUrl,
    BRow,
    BCol,
  },

  data() {
    return {
      page: 'how-to-contact-us',
      mobile: false,
      mobileNav: true,
      windowWidth: null,
      sections: [
        'contact-us',
        'product-images',
        'new-box',
        'mark-products',
        'product-labels',
        'box-size',
        'filters',
        'attaching-documents',
        'address-id',
        'booking-webservice',
        'pricing-credentials',
        'services-and-incoterms',
        'pricing',
        'general',
        'qa',
        'setup',
        'configurator',
        'price',
        undefined,
      ],
    }
  },

  mounted() {
    for (let i = 0; i < this.sections.length; i += 1) {
      if (this.$route.params.pageProp === this.sections[i]) {
        this.page = this.$route.params.pageProp
        break
      } else {
        this.page = 'wrong-url'
      }
    }

    if (this.page === undefined) {
      this.page = 'contact-us'
    }

    this.changeUrl(this.page)

    /* eslint-disable */
    window.addEventListener('resize', this.checkScreen)
    this.checkScreen()
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 27) {
        this.closeSidebar()
      }
    })
  },

  watch: {
    '$route'() {
      this.closeSidebar()
      this.showPage(this.page)
    },
  },
  /* eslint-enable */

  methods: {
    showPage(name) {
      if (name !== this.$route.params.pageProp) {
        this.page = this.$route.params.pageProp
      }
    },
    changeUrl(url) {
      this.$router.replace({ params: { pageProp: url } })
    },
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav
    },

    checkScreen() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth <= 1170) {
        this.mobile = true
        this.mobileNav = true
        return
      }
      this.mobile = false
      this.mobileNav = false
    },

    closeSidebar() {
      if (this.mobile === true) {
        this.mobileNav = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.documentation {
  .faq {
    height: 100vh;

    .main {
      background-color: white;
      position: relative;
      top: 60px;
      .sidebar {
        position: fixed;
        width: 40px;
        color: white;
        z-index: 97;
        margin: 0 0 0 14px;
        box-shadow: black 0 0 5px;

        .icon {
          align-items: center;
          padding-left: 11px;
          height: 100%;
          width: 100%;
          padding-top: 24px;
          background-color: black;

          i {
            cursor: pointer;
            font-size: 36px;
            transition: 0.8s ease all;
          }
        }
      }

      .container {
        max-width: 55%;
        @media (max-width: 1170px) {
          max-width: 100%;
          transition: all 1s ease;
        }
        @media (min-width: 1170px) {
          max-width: 55%;
          transition: all 1s ease;
        }
      }

      .menu {
      }
      .sections {
        padding-right: 10px;
        padding-left: 44px;
        @media (max-width: 1170px) {
          padding-right: 8px;
        }
      }
    }
  }
  .icon-active {
    transform: rotate(180deg);
  }

  .fade-enter-active {
    transition: opacity .5s;
    transition-delay: .5s;
  }
  .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
}
</style>
