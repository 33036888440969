<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          OBTAINING ACCESS KEY & SUBSCRIBING TO BOOKING WEBSERVICE
        </h1>
        <p>
          Usage of <b>DB Schenker</b> shipping integration requires to have approved <b><i>Shipping Booking</i></b> web service from <b>DB Schenker</b>.
          Approval can be applied for in <b>DB Schenker’s</b> management panel called <b><i>eSchenker</i></b> under the
          panel called <b><i>Usage Registration</i></b>:
        </p>
        <img
          src="../../../assets/images/help/DBSchenker/ObtainingAccessKey/1.png"
          alt=""
        >
        <p>
          After navigating to <b><i>Usage Registration</i></b>, a user must select <b><i>Maintain User – Customer</i></b> under the
          left navigation panel, where he will be redirected to the <b><i>webservice subscription</i></b> and <b><i>Access Key</i></b>
          generation page.
        </p>
        <img
          src="../../../assets/images/help/DBSchenker/ObtainingAccessKey/2.png"
          alt=""
        >
        <p>
          Afterwards, you are to make a <b><i>Booking</i></b> selection, agree to terms and conditions, and press the button
          <b><i>Save</i></b>. This will send a confirmation request to <b>DB Schenker</b> support, which may take from few hours to
          few days to get approved. When it is approved, you’ll get an email with the rest of the information.
        </p>
        <p>
          After getting a confirmation, you’ll also be provided with the <b><i>Access Key</i></b> required to set in the
          <b>Linnworks</b> integration configuration’s page under <b>Linnworks <i>Shipping Integration</i></b> for <b>DB Schenker</b>.
          If you were never provided with the key or if you forgot it or didn’t make a backup of one, you are always
          allowed to generate a new one under the <b><i>webservice subscription</i></b> page. You are also allowed to see what
          kind of webservices are you subscribed to and of course – an option to unsubscribe if anything.
        </p>
        <img
          src="../../../assets/images/help/DBSchenker/ObtainingAccessKey/3.png"
          alt=""
        >
        <p>
          When we have our access key and approved web service, this key will be used under the <b>DB Schenker</b>
          shipping service integration configuration page:
        </p>
        <img
          src="../../../assets/images/help/DBSchenker/ObtainingAccessKey/4.png"
          alt=""
        >
        <p>
          That’s it! Congratulations, you have obtained <b><i>access to the shipping booking web service</i></b>
          and you have your <b><i>Access Key</i></b> up and running.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HowToProductImages',
  components: {},
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 100%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
    }

    .container-fluid {
      background-color: white;
    }

    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    p, ul {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }

    ul {
      list-style: circle;
    }
  }
}
</style>
