<template>
  <div class="help">
    <section
      class="faq"
      style="background-color: white"
    >
      <div class="container-fluid">
        <h1 class="header">
          HOW TO IMPORT PRODUCT IMAGES BY EAN?
        </h1>
        <p>
          During product scanning, it is crucial to know what product are you scanning and one of the
          best ways to make sure that you are scanning the correct product is to import images into
          the system. Once a product is scanned, the image of the product will appear on the right
          side of your selected <b><i>Shipment</i></b> screen.
        </p>
        <p>
          To import images into the system you will need to:
        </p>
        <p>
          <ul>
            1. Open Excel/OpenOffice Calc <br>
            2. Make columns with the names: <br>
            <ul>
              – <b><i>EAN 13</i></b> <br>
              – <b><i>Images</i></b> <br>
            </ul>
            3. Fill out the columns with the following values: <br>
            <ul>
              – <b><i>EAN 13</i></b> column should contain the <b><i>EAN</i></b> of your product <br>
              – <b><i>Images</i></b> column should contain your products image url <br>
            </ul>
            4. Save the file as <b><i>.csv</i></b> (comma separated) <br>
            5. Go under <b> Settings -> Project settings -> Product images </b> and press <b><i>Browse</i></b>, choosing the file that you have created <br>
            (If you wish to overwrite/override exiting products that you have already imported previously, you can check
            the checkbox <b><i>Override existing</i></b>.) <br>
            6. Press <b><i>Upload</i></b> and you are done.
          </ul>
        </p>
        <p>
          If you wish to download already existing/previously uploaded images, you can press Download existing images.
          This file can also be used as an example for importing.
        </p>
        <img
          src="../../../assets/images/help/howToProductImage/1.png"
          alt=""
        >
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HowToProductImages',
  components: {},
}
</script>

<style lang="scss" scoped>
.help {
  .faq {
    img {
      max-width: 100%;
      box-shadow: black 0 0 15px;
      margin-bottom: 40px;
    }

    .container-fluid {
      background-color: white;
    }

    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: black;
      font-size: 42px;
      font-family: 'Open Sans', sans-serif;
    }

    p {
      text-align: justify;
      color: black;
      font-family: 'Barlow', sans-serif;
      font-size: 18px;
    }
  }
}
</style>
